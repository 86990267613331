import { navigate } from 'gatsby';
import React, { useState } from 'react'
import Layout from "../../components/layout"
import dimensions_logo from "../../assets/images/logo_up.png";
import tool_results from "../../assets/images/tool-results.png";
import header_ribbon_img from "../../assets/images/Group_4002.png"
import footer_ribbon_img from "../../assets/images/footer-bottom.png"
import emailIcon from "../../assets/images/email-icon.png"
import websiteIcon from "../../assets/images/website-icon.png"
import phoneIcon from "../../assets/images/phone-icon.png"
import locationIcon from "../../assets/images/location-icon.png"
import { GET_COMPANY_INFO } from "../../components/graphql"
import { useQuery } from "@apollo/client"
import parse from "html-react-parser"

export default function Results() {
  const [step1Percent, setStep1Percent] = useState([])
  const [percentList, setPercentList] = useState([]);
  const [step1Cost,setStep1Cost] = useState(0.0);
  const [step2Cost,setStep2Cost] = useState(0.0);
  const [mostExpensesName,setMostExpensesName] = useState("");
  const [mostExpensesAmount,setMostExpensesAmount] = useState(0.0);
  const [leastExpensesName,setLeastExpensesName] = useState("");
  const [leastExpensesAmount,setLeastExpensesAmount] = useState(0.0);
  const getCompanyInfo = useQuery(GET_COMPANY_INFO)

  React.useEffect(() => {
    console.log("result reloadeddddddddddddddddddddd",localStorage.getItem("userId"), sessionStorage.getItem('userRedirectFlag'))
    if(!localStorage.getItem("userId") || !sessionStorage.getItem('userRedirectFlag')){
        sessionStorage.removeItem('cityName');
        sessionStorage.removeItem('housingCitySelectedID');
        sessionStorage.removeItem('schoolName');
        sessionStorage.removeItem('tuitionFee');
        sessionStorage.removeItem('extraAmountList');
  
        sessionStorage.removeItem('activeHousingID');
        sessionStorage.removeItem('activeHousingPrice');
        sessionStorage.removeItem('activeIndex');
        sessionStorage.removeItem('housingExtraAmountList');
  
        sessionStorage.removeItem('step1Percent');
        sessionStorage.removeItem('step2Percent');
        sessionStorage.removeItem('step3Percent');
        sessionStorage.removeItem('step4Percent');
  
        sessionStorage.removeItem('mealsCost');
        sessionStorage.removeItem('mealsType');
        sessionStorage.removeItem('mealsTypeCost');
  
        sessionStorage.removeItem('drinksCost');
        sessionStorage.removeItem('drinksType');
        sessionStorage.removeItem('drinksTypeCost');
  
        sessionStorage.removeItem('grocery');
        sessionStorage.removeItem('groceryCost');
        sessionStorage.removeItem('groceryTypeCost');
  
        sessionStorage.removeItem('personalCare');
        sessionStorage.removeItem('personalCareCost');
        sessionStorage.removeItem('personalCareTypeCost');
  
        sessionStorage.removeItem('transportation');
        sessionStorage.removeItem('transportationCost');
        sessionStorage.removeItem('transportationTypeCost');
  
        sessionStorage.removeItem('entertainment');
        sessionStorage.removeItem('entertainmentCost');
        sessionStorage.removeItem('entertainmentTypeCost');
        sessionStorage.removeItem('otherExpense');
        navigate('/tools')
    }
    else{
        sessionStorage.removeItem('userRedirectFlag');
        
    }
    setStep1Percent(JSON.parse(sessionStorage.getItem('step1Percent')) ? JSON.parse(sessionStorage.getItem('step1Percent')) : [])
    setPercentList(JSON.parse(sessionStorage.getItem('step4Percent')) ? JSON.parse(sessionStorage.getItem('step4Percent')) : [])
    
    var step1PercentValue = JSON.parse(sessionStorage.getItem('step1Percent'))  ? JSON.parse(sessionStorage.getItem('step1Percent'))  : []
    var step1PercentValue1 = 0.0
    step1PercentValue.map((x) =>{
        if(x.label != ""){
            step1PercentValue1 = parseFloat(step1PercentValue1) + parseFloat(x.amount)
        }
    });
    setStep1Cost(step1PercentValue1? parseFloat(step1PercentValue1).toFixed(2) :0.0)
    var step4PercentValue = JSON.parse(sessionStorage.getItem('step4Percent'))  ? JSON.parse(sessionStorage.getItem('step4Percent'))  : []
    var step4PercentValue1 = 0.0
    step4PercentValue.map((x)=>{
        if(x.label !=""){
            step4PercentValue1 = parseFloat(step4PercentValue1) + parseFloat(x.amount)
        }
    }); 
    setStep2Cost(step4PercentValue1? parseFloat(step4PercentValue1).toFixed(2) :0.0)

}, []);
  
React.useEffect(() => {
    var inTemp=0; 
    var step4PercentValue = JSON.parse(sessionStorage.getItem('step4Percent'))  ? JSON.parse(sessionStorage.getItem('step4Percent'))  : []
    var mostExpensesNameTemp=""
    var mostExpensesAmountTemp = 0.0
    var leastExpensesNameTemp= ""
    var leastExpensesAmountTemp = 0.0
    step4PercentValue.map((x)=>{
        if(x.label !=""){
            if(inTemp===0){
                mostExpensesAmountTemp = parseFloat(x.amount)
                mostExpensesNameTemp = x.label
                leastExpensesAmountTemp = parseFloat(x.amount)
                leastExpensesNameTemp = x.label
            }
            inTemp = inTemp+1
            if(parseFloat(x.amount) > parseFloat(mostExpensesAmountTemp)){
                mostExpensesAmountTemp= parseFloat(x.amount)
                mostExpensesNameTemp = x.label
            }
            if(parseFloat(x.amount) < parseFloat(leastExpensesAmountTemp)){
                leastExpensesAmountTemp = parseFloat(x.amount)
                leastExpensesNameTemp = x.label
            }
        }
    }); 
    setMostExpensesAmount(mostExpensesAmountTemp);
    setMostExpensesName(mostExpensesNameTemp);
    setLeastExpensesAmount(leastExpensesAmountTemp);
    setLeastExpensesName(leastExpensesNameTemp);
    },[]);

  React.useEffect(() => {
    return () => {
      console.log("unmount results")
      // localStorage.setItem('results','true');
    }
  }, []);

  React.useEffect(() => {
    // console.log("percentListtttttttttt",percentList)
    return () => {
      sessionStorage.setItem('step4Percent', JSON.stringify(percentList))
    }
  }, [percentList]);

  const printAction = () => {
    console.log("print callinggggggggggggggggggggggggggggg")
    if (typeof window !== "undefined") {
      var divContents = document.getElementById("results-step").innerHTML;
      var a = window.open('', '', 'height=800, width=800');
      a.document.write('<html>');
      a.document.write(divContents);
      a.document.write('</body></html>');
      a.document.close();
      a.print();
      setTimeout(function () {
        a.close();
      }, 1000);
    }
  }

  const refreshPage = () => {
    if (typeof window !== "undefined") {
      sessionStorage.removeItem('cityName');
      sessionStorage.removeItem('housingCitySelectedID');
      sessionStorage.removeItem('schoolName');
      sessionStorage.removeItem('tuitionFee');
      sessionStorage.removeItem('extraAmountList');

      sessionStorage.removeItem('activeHousingID');
      sessionStorage.removeItem('activeHousingPrice');
      sessionStorage.removeItem('activeIndex');
      sessionStorage.removeItem('housingExtraAmountList');

      sessionStorage.removeItem('step1Percent');
      sessionStorage.removeItem('step2Percent');
      sessionStorage.removeItem('step3Percent');
      sessionStorage.removeItem('step4Percent');

      sessionStorage.removeItem('mealsCost');
      sessionStorage.removeItem('mealsType');
      sessionStorage.removeItem('mealsTypeCost');

      sessionStorage.removeItem('drinksCost');
      sessionStorage.removeItem('drinksType');
      sessionStorage.removeItem('drinksTypeCost');

      sessionStorage.removeItem('grocery');
      sessionStorage.removeItem('groceryCost');
      sessionStorage.removeItem('groceryTypeCost');

      sessionStorage.removeItem('personalCare');
      sessionStorage.removeItem('personalCareCost');
      sessionStorage.removeItem('personalCareTypeCost');

      sessionStorage.removeItem('transportation');
      sessionStorage.removeItem('transportationCost');
      sessionStorage.removeItem('transportationTypeCost');

      sessionStorage.removeItem('entertainment');
      sessionStorage.removeItem('entertainmentCost');
      sessionStorage.removeItem('entertainmentTypeCost');
      sessionStorage.removeItem('otherExpense');
      navigate('/tools') 
    }
  }
  return (
    <Layout setTitle={"Tools"} tag="tools">
        <div className="App results">
            {(typeof window !== "undefined")?
                <div className={`container`}>
                    <h3>Student Budget Calculator</h3>
                    <span className="main-para">
                        <p>The student budget calculator is much more than just a tool. It not only gives you an estimated 
                        overall cost per month but it also breaks down the cost to the most simplest categories based 
                        upon your inputs so that you can plan better and save as much money as possible. Get your 
                        expenses under control right away.</p>
                    </span>
                    <div className="StepperContainer" style={{marginTop:"50px"}}>
                        <ol class="progtrckr">
                            <li class="step-0 progtrckr-done no-click" value="0">
                                <em>1</em>
                                <span>School</span>
                            </li>
                            <li class="step-1 progtrckr-done no-click" value="1">
                                <em>2</em>
                                <span>Housing</span>
                            </li>
                            <li class="step-2 progtrckr-done no-click" value="2">
                                <em>3</em><span>Food &amp; Drinks</span>
                            </li>
                            <li class="step-3 progtrckr-done no-click" value="3">
                                <em>4</em><span>Expenses</span>
                            </li>
                            <li class="step-5 progtrckr-done no-click" value="5">
                                <em>5</em><span>Results</span>
                            </li>
                        </ol>
                        <div className="results-step-form row" id="results-step">
                        <style>
                            {`@media print {
                            @page{size: auto;margin: 0mm;}
                            body{
                                font-family: "Work Sans", Helvetica !important;
                                margin: 1.6cm;
                                -webkit-print-color-adjust: exact !important;
                                color-adjust: exact !important; 
                            }
                            .results-img.column{
                                display:none;
                            }
                            .site-logo{
                                display: block;
                                width: 100%;
                                text-align:right;
                                margin-bottom:15px;
                            }
                            .header-info{
                                display:block;  
                            }
                            .footer-info{
                                display:block;  
                            }
                            .site-logo img {
                                max-width: 120px;
                                text-align:right;
                            }
                            .header, .hide { visibility: hidden }
                            .mainContent.column.title {
                                margin-top: 50px;
                                display: block;
                                padding-top: 30px;
                            }
                            .mainContent .results-title-print{
                                display:block;
                            }
                            .mainContent .results-title{
                                display:none;
                            }
                            .mainContent.column h6 {
                                color: #0055a4;
                                font-size: 18px;
                                margin: 0 auto;
                                margin-bottom: 5px;
                                line-height: normal;
                                font-weight: 500;
                                font-family: "Work Sans", Helvetica !important;
                            }
                            .progressContent.column {
                                max-width: 500px;
                                margin: 30px 0;
                                display: block;
                                overflow: hidden;
                            }
                            .progressContent.column .content1 {
                                width: 48% !important;
                                float: left;
                                position: relative;
                                display: block;
                                overflow: hidden;
                            }
                            .progressContent.column .content2 {
                                width: 48% !important;
                                float: right;
                                position: relative;
                                display: block;
                                overflow: hidden;
                            }
                            .progressContent .progress {
                                display: block;
                                height: 300px;
                                margin: 5px 5px 20px;
                                border-radius: 30px;
                            }
                            .progressContent .progress > .progress-bar:first-child:first-child {
                                border-top-left-radius: 25px;
                                border-top-right-radius: 25px;
                            }
                            .progressContent .progress > .progress-bar:last-child {
                                border-bottom-left-radius: 25px;
                                border-bottom-right-radius: 25px;
                            }
                            .progressContent.column .content1 .progress .progress-bar span, .progressContent.column .content2 .progress .progress-bar span {
                                position: absolute;
                                color: #3377b6;
                                font-weight: 500;
                                white-space: normal;
                                text-align: left;
                                word-break: break-word;
                                font-size: 8px;
                                font-family: "Work Sans", Helvetica !important;
                            }
                            .progressContent.column .progressContent .content2, progressContent.column .progressContent .content1 {
                                width: 100% !important;
                                float: left;
                            }
                            .bottom-section {
                                display: none;
                            }
                            .progressContent.column .progressContent .content1, .progressContent.column .progressContent .content2 {
                                font-size: 20px;
                                font-weight: 500;
                                color: #0055a4;
                            }
                            .progressContent.column .progressContent .content2 {
                                font-size: 14px;
                                color: #0055a4;
                            }
                            .progress .progress-bar:last-child {
                                border-bottom-left-radius: 25px;
                                border-bottom-right-radius: 25px;
                            }
                            .progressContent .progress .progress-bar {
                                display: block;
                                width: 50px;
                                margin-bottom: 0;
                                background-color: #F5F8FC;
                                margin: 0 auto;
                            }
                            
                            .progressContent.column .content1 .progress .progress-bar span .p-label,
                            .progressContent.column .content2 .progress .progress-bar span .p-label  {
                                display: inline-block;
                                margin: 0 auto;
                            }
                            .progressContent.column .content1 .progress .progress-bar span .p-value,
                            .progressContent.column .content2 .progress .progress-bar span .p-value {
                                display: inline-block;
                                margin: 0 auto;
                                margin-left: 5px;
                                color: #35DF90;
                            }
                            .exp-next .exp-next-list .price-section .currency-symbol {
                                top: 0;
                            }
                            .exp-next-list {
                                display: flex;
                                margin-bottom: 8px;
                                overflow: hidden;
                            }
                            .exp-next-list label {
                                margin-right: 15px;
                                font-weight: 600;
                                color: #000;
                                font-size: 13px;
                            }
                            .exp-next-list .pricedata {
                                display: flex;
                                align-items: center;
                                max-width: 100px;
                                overflow: hidden;
                            }
                            .exp-next-list .pricesection {
                                display: flex;
                                height: 24px;
                                max-width: 350px;
                                justify-content: flex-end;
                            }
                            .exp-next-list .pricedata span.currency-symbol {
                                line-height: normal;
                                min-height: auto;
                                margin-right: 5px;
                                margin-left: 10px;
                                color: #35DF90;
                            }
                            .exp-next-list .pricedata input.fullwidth {
                                line-height: normal;
                                height: auto;
                                min-height: initial;
                                width: auto !important;
                                display: flex;
                                color: #35DF90 !important;
                                max-width: 150px;
                                border: 0;
                                font-weight: 600;
                                text-decoration: underline;
                            }
                            .exp-next-list .pricesection .price-input input.fullwidth {
                                max-width: 150px;
                                border: 0;
                                font-weight: 600;
                                color: #0055a4 !important;
                                text-decoration: underline;
                            }
                            .address-info {
                                margin: 25px 0;
                                padding: 5px;
                                display: block;
                                overflow: hidden;
                                position: absolute;
                                bottom: 40px;
                            }
                            .address-info .name {
                                font-size: 13px;
                            }
                            .address-line-1, .address-line-2 {
                                float: left;
                                width: 100%;
                                padding: 6px 0;
                            }
                            .address-line-1 .location, .address-line-2 .phone {
                                float: left;
                                width: 48%;
                            }
                            .address-line-1 .email, .address-line-2 .website {
                                width: 48%;
                                float: right;
                            }
                            .address-info .icon {
                                margin: 0 auto;
                                margin-right: 10px;
                            }
                            .address-info .icon img {
                                margin: 0 auto;
                            }
                            .mainContent.column {
                                padding-bottom: 15px;
                                max-width: 100%;
                            }
                            .content-area {
                                font-size: 15px;
                                line-height: 1.5715;
                                font-family: "Work Sans", Helvetica !important;
                                max-width: 75%;
                                font-size: 13px;
                            }
                            .content-area span {
                                line-height: normal;
                                display: block;
                            }
                            .footer-img {
                                display: block;
                                overflow: hidden;
                                width: 100%;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                            }
                            .footer-img img {
                                max-width: 100%;
                                margin: 0 auto;
                            }
                            .header-info .header-img {
                                width: 70%;
                                display: block;
                                float: left;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                            .header-info .header-img img {
                                margin: 0 auto;
                            }
                            .progressContent.column .progressContent .content2 {
                                width: 150px !important;
                            }
                            .progressContent.column .progress-bar:nth-child(even) > span, .progress-bar:nth-child(even) > span {
                                right: calc(50% + 30px);
                                left: auto;
                                text-align: right;
                            }
                            .progressContent.column .progress-bar:nth-child(odd) > span {
                                left: calc(50% + 35px);
                                right: auto;
                            }
                            }
                            `}
                        </style>
                        <div className='header-info'>
                            <span className='header-img'>
                                <img src={header_ribbon_img} alt="Dimensions Logo"></img>
                            </span>
                            <span className="site-logo">
                                <img src={dimensions_logo} alt="Dimensions Logo"></img>
                            </span>
                        </div>
                        <div className="mainContent column title">
                            <h6 className="pb15 results-title">Results</h6>
                            <h6 className="pb15 results-title-print">Results From the Student Budget Calculator</h6>
                            <span>Your expense sheet looks something like this!</span>
                        </div>
                        <div className="progressContent column" >
                            <div className="content1" style={{ width: "100%" }}>
                            <div className="progress">
                            {step1Percent !== null ? step1Percent.map((x, i) => {
                                return (
                                    i == 0 ?
                                    <div className={x.value === '100.00' ?
                                    "progress-bar bg-white progress-bar-stripped curved":
                                    "progress-bar bg-white progress-bar-stripped"} 
                                     style={{ height: x.value + '%' }}>
                                    </div>
                                    :
                                    ( i===1?
                                          <div className="progress-bar main-element"
                                            style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                                            <span>{x.value >0 ? <><p className="p-label">{x.label}</p><p className="p-value">{"  € "+ (parseFloat(x.amount)?parseFloat(x.amount).toFixed(2):0.0)}</p></>:""}</span>
                                            <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                                            </div>
                                          </div>
                                        :
                                         <div className="progress-bar main-element"
                                        style={{ height: x.value + "%",backgroundColor: x.color }}>
                                          <span>{x.value >0 ? <><p className="p-label">{x.label}</p><p className="p-value">{"  € "+ (parseFloat(x.amount)?parseFloat(x.amount).toFixed(2):0.0)}</p></>:""}</span>
                                          <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                                          </div>
                                          <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                                          </div>
                                        </div>
                                    )
                                )
                                }) : null}
                            </div>
                            <div className="progressContent">
                                <span className="content1">€ {step1Cost}</span><br />
                                <span className="content2">Estimated costs for the school year.</span>
                            </div>
                            </div>
                            <div className="content2" style={{ width: "100%" }}>
                            <div className="progress">
                                {percentList !== null ? percentList.map((x, i) => {
                                return (
                                    i == 0 ?
                                    <div className={x.value === '100.00' ?
                                        "progress-bar bg-white progress-bar-stripped curved":
                                        "progress-bar bg-white progress-bar-stripped"} 
                                    style={{ height: x.value + '%' }}>
                                    </div>
                                    :
                                     ( i===1?
                                            <div className="progress-bar main-element"
                                            style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                                            <span>{x.value >0 ? <><p className="p-label">{x.label}</p><p className="p-value">{"  € "+ (parseFloat(x.amount)?parseFloat(x.amount).toFixed(2):0.0)}</p></>:""}</span>
                                            <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                                            </div>
                                          </div>
                                        :
                                         <div className="progress-bar main-element"
                                        style={{ height: x.value + "%",backgroundColor: x.color }}>
                                          <span>{x.value >0 ? <><p className="p-label">{x.label}</p><p className="p-value">{"  € "+ (parseFloat(x.amount)?parseFloat(x.amount).toFixed(2):0.0)}</p></>:""}</span>
                                          <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                                          </div>
                                          <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                                          </div>
                                        </div>
                                    )
                                )
                                }) : null}
                            </div>
                            <div className="progressContent">
                                <span className="content1">€ {step2Cost}</span><br />
                                <span className="content2">Estimated costs on other expenses</span>
                            </div>
                            </div>
                        </div>
                        <div className="results-img column">
                            <img src={tool_results} alt="Tool results image"></img>
                        </div>
                        <div className="mainContent column">
                            <div className='row pb20'>
                            <div className='exp-next'>
                                <div className="exp-next-list">
                                <label>Section that has the most expenses incurred in a Month:</label>
                                <div className='pricesection'>
                                    <div className="price-input">
                                        <input
                                        className='fullwidth'
                                        placeholder='0.0'
                                        type='text'
                                        onChange={e => setMostExpensesName(e.target.value)}
                                        value={mostExpensesName}
                                        readOnly={true}
                                        />
                                    </div>
                                    <div className="pricedata">
                                        <span className='currency-symbol'>€</span>
                                        <input
                                        className='fullwidth'
                                        placeholder='0.0'
                                        type='text'
                                        onChange={e => setMostExpensesAmount(e.target.value)}
                                        value={parseFloat(mostExpensesAmount).toFixed(2)}
                                        readOnly={true}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className='exp-next'>
                                <div className="exp-next-list">
                                <label>Section that has the least expenses incurred in a Month:</label>
                                <div className='pricesection'>
                                    <div className="price-input">
                                        <input
                                        className='fullwidth'
                                        placeholder='0.0'
                                        type='text'
                                        value={leastExpensesName}
                                        readOnly={true}
                                        />
                                    </div>
                                    <div className="pricedata">
                                        <span className='currency-symbol'>€</span>
                                        <input
                                        className='fullwidth'
                                        placeholder='0.0'
                                        type='text'
                                        value={parseFloat(leastExpensesAmount).toFixed(2)}
                                        readOnly={true}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='content-area pb20'>
                            <span>
                                <p>Lo Behold !</p>
                                <p>As we had promised, so have we delivered. Find above a complied list of all the individual costs based upon your inputs, and also the total overall cost.</p>
                                <p>If you feel like there is something missing, feel free to start again, or else talk to our experts for a one to one session today and get all your doubts cleared.</p>
                            </span>
                        </div>
                        <div className="bottom-section">
                            <div className="cta-secondary">
                                <input type="button" value="Print" className="custom-button print-btn" onClick={(e) => printAction()} />
                            </div>
                            <div className="cta-primary">
                                <input type="button" value="Start Over" className="custom-button refresh-btn" onClick={(e) => refreshPage()} />
                            </div>
                        </div>
                        <div className='footer-info'>
                            {getCompanyInfo.data?
                            <div className="address-info">
                                {getCompanyInfo.data.companyInfo?
                                <>
                                    <div className="address-line-1">
                                        {getCompanyInfo.data.companyInfo.companyAddress?
                                            <div className='location'>
                                                <span className='location-icon icon'><img src={locationIcon} alt="Location"></img></span>
                                                <span className='location-name name'>{parse(getCompanyInfo.data.companyInfo.companyAddress)}</span>
                                            </div>
                                        :null}
                                        {getCompanyInfo.data.companyInfo.emailAddress?
                                            <div className='email'>
                                                <span className='email-icon icon'><img src={emailIcon} alt="Email"></img></span>
                                                <span className='email-name name'>{getCompanyInfo.data.companyInfo.emailAddress}</span>
                                            </div>
                                        :null}
                                    </div>
                                    <div className="address-line-2">
                                        {getCompanyInfo.data.companyInfo.contactNumber?
                                            <div className='phone'>
                                                <span className='phone-icon icon'><img src={phoneIcon} alt="Phone"></img></span>
                                                <span className='phone-name name'>{getCompanyInfo.data.companyInfo.contactNumber}</span>
                                            </div>
                                        :null}
                                        {getCompanyInfo.data.companyInfo.websiteURL?
                                            <div className='website'>
                                                <span className='website-icon icon'><img src={websiteIcon} alt="Website"></img></span>
                                                <span className='website-name name'>{getCompanyInfo.data.companyInfo.websiteURL}</span>
                                            </div>
                                        :null}
                                    </div>
                                </>
                                :null}
                            </div>
                                :null}
                            <div className='footer-img'>
                                <img src={footer_ribbon_img} alt="Dimensions Logo"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :""}
        </div>
    </Layout>
  )
}   